<template>
  <div>
    <b-card-body class="submit-content">
      <div id="cart-div" :class="{ productInCart: !cartEmpty }">
        <checkout-cart-body
          id="verify-order-checkout-cart-body"
          :bus="bus"
          :dashed="true"
          :total="true"
          select-payment-method-card-header-text="Method of Payment"
          :alternate-checkout-button-label="checkoutButtonLabel"
          :verify-order-checkout="verifyOrderCheckout"
          :verify-order-checkout-button-overrides="verifyOrderCheckoutButtonOverrides"
          @checkout-completed="$emit('submit')"
        >
          <template #cart-header>
            <h5 class="card-header-text">
              Order Summary
            </h5>
            <hr>
          </template>
        </checkout-cart-body>
      </div>
    </b-card-body>
    <b-card-footer
      v-if="checkoutButtonLabel === 'Submit' && cartEmpty && verifyOrderCheckout"
      footer-tag="footer"
      class="accordion-footer pt-4 remove-line"
      role="tab"
    >
      <b-button
        variant="primary"
        class="button-submit"
        @click="$emit('submit')"
      >
        <span>
          {{ checkoutButtonLabel }}
        </span>
      </b-button>
    </b-card-footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CheckoutCart',
  components: {
    CheckoutCartBody: () => import('@/components/shared/CheckoutCartBody'),
  },
  props: {
    bus: {
      type: Object,
      default: null,
    },
    verifyOrderCheckout: {
      type: Boolean,
      default: false,
    },
    verifyOrderCheckoutButtonOverrides: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('checkout', [
      'cartEmpty',
    ]),
    ...mapGetters('paymentMethods', [
      'selectedPaymentMethod',
    ]),
    ...mapGetters('verifyOrder', [
      'upsellProductInCart',
    ]),
    checkoutButtonLabel() {
      return this.upsellProductInCart ? 'Submit & Checkout' : 'Submit'
    },
  },
}
</script>

<style lang="scss" scoped>
.submit-content {
  padding: 0;

  #cart-div {
    &:not(.productInCart) {
      ::v-deep .card {
        display: none;
      }
    }

    ::v-deep .checkout-cart-body {
      min-height: 0;

      .card:nth-child(1) {
        border-radius: 0.35rem 0.35rem 0 0;

        .container-fluid {
          padding: 0.25rem 0 0.5rem;

          .card-header {
            padding: 0 !important;

            .card-header-text {
              margin: 0;
              padding: 0.75rem 0 !important;
              text-align: center;
              font-weight: bolder;
              font-size: 1em;
            }

            em {
              padding-left: 1.2rem;
            }
          }
        }
      }

      .card:nth-child(2), .card:nth-child(3) {
        border-radius: 0;
      }

      .card:nth-child(3) {
        border-bottom: 0.063em solid rgba(0, 0, 0, 0.125);
        font-size: 1em;
        padding-top: 0.65rem;

        #select-payment {
          padding-right: 0.875em;

          .card-header {
            .row {
              .card-header-text {
                margin-top: 0;
                font-size: 1em;
              }

              div:nth-child(2) {
                text-align: center;

                img {
                  width: 1.563em;
                  aspect-ratio: auto 25 / 16;
                  height: 1em;
                  align-self: center;
                }
              }

              button {
                margin-top: -0.55rem;
              }

              svg {
                vertical-align: text-top;
              }
            }
          }
        }
      }

      .billing-pay {
        padding: 0;

        button {
          margin-top: 5rem;
          border-radius: 0.35rem;
          width: min-content !important;
          white-space: nowrap;
          float: right;
        }
      }
    }
  }

  .button-submit {
    background: $ct-ui-primary !important;
    border-color: $ct-ui-primary !important;

    &:hover {
      cursor: pointer;
      background: $ct-ui-primary-dark !important;
      border-color: $ct-ui-primary !important;
    }
  }
}

.card-footer {
  border-top: 0 !important;
}
</style>
